import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      token: null,
      refreshToken: null,
      tenants: null,
      permissionIds: [],
      userId: "",
      userName: "",
      ownerId: "",
      ownerName: "",
      tenantId: "",
      tenantName: "",
      facilityTypeId: "",
      isLoggedIn: false,
      markets: [],
      title: "",
    },
  },
  reducers: {
    updateUserData: (state, payload) => {
      state.data = payload.payload;
    },
    clearUserData: (state) => {
      state.data = {
        token: null,
        refreshToken: null,
        tenants: null,
        permissionIds: [],
        userId: "",
        userName: "",
        ownerId: "",
        ownerName: "",
        tenantId: "",
        tenantName: "",
        facilityTypeId: "",
        isLoggedIn: false,
        markets: [],
        title: "",
      };
    },
  },
});

export const { updateUserData, clearUserData } = userSlice.actions;
export default userSlice.reducer;
