import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import axios_API, { axios_AUTH, axios_ACF } from "config/axiosInstances";

export default function BaseURLDialog({ open, handleClose }) {
  const [stp, setStp] = useState(process.env.REACT_APP_BASE_URL_API);
  const [auth, setAuth] = useState(process.env.REACT_APP_BASE_URL_AUTH);
  const [acf, setAcf] = useState(process.env.REACT_APP_BASE_URL_ACF);

  const handleChange = () => {
    axios_AUTH.defaults.baseURL = auth;
    axios_API.defaults.baseURL = stp;
    axios_ACF.defaults.baseURL = acf;
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Developers Only</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Change the baseURL to your local host
        </DialogContentText>
        <TextField
          value={stp}
          onChange={(e) => setStp(e?.target?.value)}
          // autoFocus
          // margin="dense"
          id="baseUrl"
          label="Base URL"
          sx={{ marginBottom: "0.5rem" }}
          fullWidth
        />
        <TextField
          value={auth}
          onChange={(e) => setAuth(e?.target?.value)}
          // autoFocus
          // margin="dense"
          sx={{ marginBottom: "0.5rem" }}
          id="baseUrl"
          label="Auth URL"
          fullWidth
        />
        <TextField
          value={acf}
          onChange={(e) => setAcf(e?.target?.value)}
          // autoFocus
          // margin="dense"
          sx={{ marginBottom: "0.5rem" }}
          id="baseUrl"
          label="ACF URL"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleChange} variant="contained" color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
