// start UserManagement permissions
const UserManagement = "UserManagement";
// Message Configurations permissions in Anti Counterfeit
const LandingPageViewing = "LandingPageViewing",
  LandingPageEditing = "LandingPageEditing",
  LandingPageApproval = "LandingPageApproval",
  SMSApproval = "SMSApproval",
  SMSViewing = "SMSViewing",
  SMSEditing = "SMSEditing",
  ShareAppViewing = "ShareAppViewing",
  ShareAppEditing = "ShareAppEditing",
  ShareAppApproval = "ShareAppApproval";

// Alarm permissions Anti Counterfeit
const StackAggregation = "StackAggregation";

// Alarm permissions Anti Counterfeit
const AlarmViewing = "AlarmViewing",
  AlarmCreation = "AlarmCreation",
  AlarmActivation = "AlarmActivation",
  AlarmDeactivation = "AlarmDeactivation",
  AlarmNotificationViewing = "AlarmNotificationViewing",
  AlarmNotificationAcknowledge = "AlarmNotificationAcknowledge",
  CreateReport = "CreateReport",
  ViewReportDetails = "ViewReportDetails",
  ViewAllReports = "ViewAllReports";

// Usrer Statistics Anti Counterfeit
const ViewUserStatistics = "ViewUserStatistics";

// Repeated Serials
const RepeatedSerialsViewing = "RepeatedSerialsViewing",
  RepeatedSerialsChange = "RepeatedSerialsChange";

// Inbound Order
const INOView = "INOView";

// Out Order
const OutOrderView = "OutOrderView";

//Capping WorkOrder
const CWOView = "CWOView",
  CAPConfiguration = "CAPConfiguration";

// Aggregation
const AGG_ACIPDisAggregate = "AGG_ACIPDisAggregate",
  AGG_Aggregate = "AGG_Aggregate",
  StackConfiguration = "StackConfiguration";

// Inventory
const INV_ViewReport = "INV_ViewReport";

// PrintersAndDevices
const PrintingConfiguration = "PrintingConfiguration";

// Supply chain and Master Data
const StockDashboardViewing = "StockDashboardViewing",
  WADViewing = "WADViewing",
  WADCreation = "WADCreation",
  CAPViewing = "CAPViewing",
  CAPCreation = "CAPCreation",
  EndProductViewing = "EndProductViewing",
  EndProductCreation = "EndProductCreation",
  PrintHousesViewing = "PrintHousesViewing",
  PrintHouseCreation = "PrintHouseCreation",
  AggregationHouseViewing = "AggregationHouseViewing",
  AggregationHouseCreation = "AggregationHouseCreation",
  CappingVendorsViewing = "CappingVendorsViewing",
  CappingVendorCreation = "CappingVendorCreation",
  LOBPViewing = "LOBPViewing",
  LOBPCreation = "LOBPCreation",
  ProductVerification = "ProductVerification",
  PartnerViewing = "PartnerViewing", // this permission for LinkFacilities
  PartnerCreation = "PartnerCreation"; // this permission for LinkFacilities

// Audit trail
const ViewAuditTrail = "ViewAuditTrail"; // this permission for Audit Trail

// user
const ViewUsers = "ViewUsers",
  CreateUser = "CreateUser",
  EditUser = "EditUser",
  UserActivation = "UserActivation";
// Roles
const RoleViewing = "RoleViewing",
  RoleCreation = "RoleCreation",
  RoleEditing = "RoleEditing";

// Machine Configurations
const MachineCreation = "MachineCreation",
  MachineViewing = "MachineViewing";
// verify status
const VerifyPrintHouseSerial = "VerifyPrintHouseSerial",
  VerifyAggregationHouseSerial = "VerifyAggregationHouseSerial";

const allPermissions = {
  UserManagement,
  StackAggregation,
  LandingPageViewing,
  LandingPageEditing,
  LandingPageApproval,
  SMSApproval,
  SMSViewing,
  SMSEditing,
  ShareAppViewing,
  ShareAppEditing,
  ShareAppApproval,
  AlarmViewing,
  AlarmCreation,
  AlarmActivation,
  AlarmDeactivation,
  AlarmNotificationViewing,
  AlarmNotificationAcknowledge,
  CreateReport,
  ViewReportDetails,
  ViewAllReports,
  INOView,
  OutOrderView,
  CWOView,
  AGG_ACIPDisAggregate,
  AGG_Aggregate,
  INV_ViewReport,
  PrintingConfiguration,
  StockDashboardViewing,
  WADViewing,
  WADCreation,
  CAPViewing,
  CAPCreation,
  EndProductViewing,
  EndProductCreation,
  PrintHousesViewing,
  PrintHouseCreation,
  AggregationHouseViewing,
  AggregationHouseCreation,
  CappingVendorsViewing,
  CappingVendorCreation,
  LOBPViewing,
  LOBPCreation,
  ProductVerification,
  RepeatedSerialsViewing,
  RepeatedSerialsChange,
  ViewUsers,
  CreateUser,
  EditUser,
  UserActivation,
  RoleViewing,
  RoleCreation,
  RoleEditing,
  MachineCreation,
  MachineViewing,
  VerifyPrintHouseSerial,
  VerifyAggregationHouseSerial,
  PartnerViewing,
  PartnerCreation,
  CAPConfiguration,
  StackConfiguration,
  ViewAuditTrail,
  ViewUserStatistics,
};

export default allPermissions;
