import { useSelector } from "react-redux";

const usePermission = () => {
  const userPermissions = useSelector(
    (state) => state?.user?.data?.permissionIds ?? []
  );

  const hasPermission = (permissions) => {
    if (!permissions) return true;

    if (Array.isArray(permissions)) {
      // Check if the user has all the permissions in the "permissions" array
      return permissions.every((permission) =>
        userPermissions.includes(permission)
      );
    } else {
      // Check if the user has the single permission
      return userPermissions.includes(permissions);
    }
  };

  return hasPermission;
};

export default usePermission;
