import React from "react";
import { Navigate } from "react-router-dom";
import usePermission from "Hooks/usePermission";

const PrivateRoute = ({ children, requiredPermissions }) => {
  const hasPermission = usePermission();

  return hasPermission(requiredPermissions) ? (
    children
  ) : (
    <Navigate to="/accessForbidden" />
  );
};

export default PrivateRoute;
