import { createSlice } from "@reduxjs/toolkit";

const navigationDataSlice = createSlice({
  name: "navigationData",
  initialState: {
    data: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    clearData: (state) => {
      state.data = null;
    },
  },
});

export const { setData, clearData } = navigationDataSlice.actions;

// export const selectNavigationData = (state) => state.navigationData.data;

export default navigationDataSlice.reducer;
